<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Wizard Body-->
                <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
                    <div class="col-xl-12 col-xxl-7">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon2-gear settingicon"></i> {{title_text}}</h3>

                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Screen Name</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.screen_name">
                                            <span class="form-err" v-if="form.errors.has('screen_name')" >
                                              {{form.errors.get('screen_name')}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Screen Scope</label>
                                            <input type="text" class="form-control form-control-solid form-control-lg" v-model="form.screen_scope_name">
                                            <span class="form-err" v-if="form.errors.has('screen_scope_name')" >
                                              {{form.errors.get('screen_scope_name')}}
                                            </span>
                                        </div>
                                    </div>
                                     <div class="col-xl-12">
                                        <div class="form-group">
                                            <label>Module</label>
                                            <select class="select2 form-control form-control-solid form-control-lg" v-model="form.controller_id">
                                                <option :value="null" disabled>Select Module</option>
                                                <option v-for="module in module_list" :value="module.id"  v-bind:key="module.id">
                                                {{module.controller_name}}
                                                </option>
                                            </select>
                                            <span class="form-err" v-if="form.errors.has('controller_id')" >
                                                {{form.errors.get('controller_id')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--begin: Settings Actions -->
                            <div class="d-flex justify-content-between border-top pt-10">
                                <a @click="createScreen" class="text-white">
                                    <div class="btn btn-primary font-weight-bold text-uppercase px-9 py-4">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Settings Actions -->
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>


<style>
.settingicon{font-size:30px;}
select.select2{opacity:1;}
.preview-container img{max-width:200px;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import {getScreenDetails,getAllModulesList} from "@/api/access";

export default {
  name: 'add_edit_screen',
  data() {
    return {
      loading:false,
      error:null,
      form : new Form({
        controller_id : null,
        screen_name : null,
        screen_scope_name : null,
      }),
      module_list : [],
      submit_btn_text: 'Add Screen',
      title_text: 'Add Screen',
      api_url: '/screen/create',
      response_msg: 'Screen created successfully.'
    }
  },
  components: {},
   mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Screens", route: "/screen/list" },
      { title: "Add/Edit Screen" }
    ]);
  },
    methods:{
      createScreen: function() {
        this.loading=true;
        this.form.post(this.api_url).then((response) => {
          if(response.status){
              this.$router.push({ path: '/screen/list' })
              this.loading=false;
              this.$notify({
                group: 'ctshub',
                type: 'success',
                text: this.response_msg,
                speed: 1000
              });
          }
        })
      },
      getAllModulesList(){
      this.loading = true
      getAllModulesList().then(response => {
        this.module_list = response.data.data
        this.loading = false
      })
    },
    },
    created(){
    let screenId = this.$route.params.screenId;

    if(parseInt(screenId) > 0){
      
      this.submit_btn_text = 'Update Screen'
      this.title_text = 'Update Screen'
      this.response_msg = 'Screen updated successfully.'

     getScreenDetails(screenId).then(response => {
        if(response.data.data.id){
          this.api_url = 'screen/update/' + response.data.data.id
        }
        this.form = new Form(response.data.data)
      });
    }
    this.getAllModulesList();

  }
}
</script>
